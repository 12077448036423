<script>
    // OrderItemTile
    // A tile for an item in an order. Typically used when viewing order detail.
    // TODO: Move this to a more appropriate folder

    // Setup ---
    // Use the values below to modify how the tile appears
    const setup = {
        // tileHeight: '9rem', // Height of the full tab, fixed for alignment purposes
        productNameLines: 2 // Number of lines product name is allowed to wrap before being trimmed with ellipses
    };

    export const Setup = setup;
</script>

<script setup>
    // Imports ----
    import { defineProps, ref, reactive, computed, onMounted, watch } from 'vue';
    import ProductPdfControls from '@/site/components/ProductPdfControls.vue';
    import { AddToCartForm } from '@dd-nucleus/nucleus-vue';
    import LimitMessages from '@/site/components/LimitMessages.vue';
    import { store, CompanionItemTile } from '@dd-nucleus/nucleus-vue';
    import ConferenceOrderTile from '@/site/components/ConferenceOrderTile'
    import { STOCK_EXCEED_MESSAGE } from '@/constants';

    // Props ----
    const props = defineProps({
        // The cart item
        item: {
            type: Object,
            required: true
        }
    });

    const product = ref(props.item.product);
    const form = reactive(new AddToCartForm(product.value, null, STOCK_EXCEED_MESSAGE));
    const quantityLabel = computed(() => {
        return store.config.store.unitOfMeasures.find(unit => unit.id?.toLowerCase() === product.value.unitOfMeasure?.toLowerCase())?.text ?? null;
    });
    const quantityUnit = computed(() => {
        return `Quantity: ${product.value.quantityPerUnit} ${product.value.quantityPerUnit > 1 ? 'units' : 'unit'} per ${quantityLabel.value}`;
    });

    onMounted(async () => {
        await form.validate();
    });

    // State ----

    const customUnit = computed(() => {
        if (!store?.userconfig?.isUomToBeBroken) return '';

        return store?.userconfig?.defaultUnitOfMeasure;
    });

    const companionItems = computed(() => {
        const orderedCompanionItems = props.item.companions;

        return orderedCompanionItems.map((cp) => {
            cp.id = cp.productId;
            cp.name = cp.productName;
            delete cp.productId;
            delete cp.productName;
            return cp;
        });
    });

    const conferenceType = ref(store.cart.current.attributes?.allowLimitOverride === "true");

    store.cart.onCartLoaded(() => {
        conferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";
    });

    store.cart.onCartUpdated(() => {
        conferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";
    });

    const hasLimitExceeded = ref(false)

    const hasStockExceeded = ref(false)


    watch(() => form.model, () => {
        hasLimitExceeded.value = form.limits?.length > 0 ?? false;
        hasStockExceeded.value = form.product.quantityAvailable < form.model.quantity ? true : false;
    }, { deep: true, immediate: true });

</script>

<template>
    <div class="order-item-tile" :style="{ height: setup.tileHeight }">
        <div class="inner-row d-flex flex-row" style="gap: 8px;">
            <div class="left">
                <ProductThumbnail :product="props.item.product" :height="setup.tileHeight" no-image-available-url="/images/no-image.png" />
            </div>
            <div class="middle flex-fill">
                <!-- Stretchy middle part-->
                <div class="tags mb-2">
                    <ProductTags :product="props.item.product" />
                </div>

                <!-- Product name and item number -->
                <router-link class="title-link" :to="`/product/${props.item.product.id}`">
                    <div class="name" v-snip="{ lines: setup.productNameLines }" :title="item.product.name">
                        {{ props.item.product.name }}
                    </div>
                </router-link>
                <div class="item-number">
                    <router-link class="title-link" :to="`/product/${props.item.product.id}`">{{ props.item.product.itemNumber }}</router-link>
                </div>

                <div class="companion-list" v-for="companion in companionItems" :key="companion.id">
                    <CompanionItemTile class="companion" :companion="companion" />
                </div>
            </div>
            <!-- Fixed right panel -->
            <div class="right d-flex flex-column" :style="{ height: setup.tileHeight }">
                <div>
                    <ItemQty :item="props.item" />
                </div>
                <div class="favorite">
                    <ProductFavoriteIcon :product="props.item.product" label="Add as Favorite" selected-label="Favorite" />
                </div>
                <div class="messages">
                    <MessageList :messages="props.item.messages" :add-box="false" />
                </div>
                <div class="shipment-status">
                    <div v-if="props.item.status === 'Delivered'" class="complete"><i class="bi bi-check-circle-fill"></i> SHIPPED</div>
                    <div v-if="props.item.status === 'Canceled'" class="cancel"><i class="bi bi-x-circle-fill"></i> CANCELED</div>
                </div>
                <div class="p-0" style="margin-top: auto;">
                    <ProductPdfControls :media="props.item.media" />
                </div>
                
            </div>
            <div class="right d-flex m-auto" v-if="product.isAvailableSoon">
                <i class="bi bi-hourglass-split"></i>
                <i><p>Coming soon...</p></i>
            </div>
            <div class="right d-flex flex-column" v-else>
                <p v-if="product.quantityPerUnit > 0 && !store?.userconfig?.isUomToBeBroken">{{ quantityUnit }}</p>
                <p v-if="store?.userconfig?.isUomToBeBroken">Quantity: 1 unit / {{ store?.userconfig?.defaultUnitOfMeasure }}</p>
                <FormContainer :form="form" v-if="product.canAddToCart">
                    <div class="d-flex flex-column h-100" >
                        <div class="flex-fill">
                            <ProductAddToCartQty :product="product" :unit="customUnit" />
                            <FormMessages :showOnlySuccessMessagesInStock="conferenceType ? true : false" field-name="quantity" :add-box="false" class="mt-2" />
                            <FieldMessages field-name="quantity" class="mt-2" />
                        </div>
                    <LimitMessages :product="product" class="mt-2" />
                    <ConferenceOrderTile v-if="form.validator.isInvalid && !conferenceType && hasLimitExceeded && !hasStockExceeded" title="Conference Order" />
                    <div class="d-grid">
                        <button class="btn btn-outline-color-1" @click="form.submit(validate=!conferenceType)" :disabled="conferenceType ? hasStockExceeded : form.status.isInvalid">ADD TO CART</button>
                    </div>
                </div>
                </FormContainer>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .order-item-tile {
        p {
            font-size: 12px;
        }

        .inner-row {
            border-bottom: 1px solid $gray-20;
            padding-bottom: 1rem;
        }

        .name {
            font-size: 1rem;
            line-height: 1.1rem;
        }

        a.title-link,
        a.title-link:visited,
        a.title-link:hover {
            color: var(--bs-body-color);
            text-decoration: none;
        }

        .item-number a.title-link {
            font-size: 0.7rem;
            color: $gray-50;
        }

        .middle {
            padding-left: 1rem;
        }

        .favorite {
            margin-top: 1rem;
        }

        .right {
            width: 14rem;
        }

        .shipment-status {
            .complete {
                color: $ok;
                font-weight: $bold;
            }
        }

        .shipment-status {
            .cancel {
                color: $error;
                font-weight: $bold;
            }
        }
    }
</style>
